<template>
  <div class="submit-modal">
      <md-dialog :md-active.sync="submitModal.show" :md-fullscreen="false">
        <transition appear enter-active-class="zoomInFade">
          <div>
            <div class="dialog-content-submit-modal">
              <transition appear enter-active-class="zoomIn">
                <i v-if="submitModal.success" class="status-icon success-icon fas fa-check"></i>
                <i v-if="!submitModal.success" class="status-icon error-icon fas fa-times"></i>
              </transition>
              <p class="headline">{{submitModal.headline}}</p>
              <p class="message">{{submitModal.message == "" && !submitModal.success ? lang.error.defaultMessage : submitModal.message}}</p>
              <md-dialog-actions v-if="!submitModal.success">
                <md-button class="md-primary md-round md-raised md-filled" @click="closeDialog()">{{lang.modal.buttonTextOkay}}</md-button>
              </md-dialog-actions>
            </div>
          </div>
        </transition>
      </md-dialog>
  </div>
</template>

<script>
import store from '@/store/index'

export default {
  name: 'SubmitModal',
  methods: {
    closeDialog() {
      store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false);
    }
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    submitModal: {
      get: function () {
        return store.getters.getSubmitModal;
      },
      set: function (newValue) {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/base';

.md-dialog .md-dialog-container {
  max-width: 768px;
  z-index: 200;
}

.dialog-content-submit-modal {
  padding: 30px 20px;
}

.status-icon {
  font-size: 9rem;
  margin: 20px 0;
  text-align: center;
  display: block;
}

.success-icon {
  color: $color-success;
}

.error-icon {
  color: $brand-primary;
}

.headline {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.message {
  margin-bottom: 20px;
  text-align: center;
}

.md-button {
  padding: 0 20px;
}
</style>
