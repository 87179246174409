<template>
    <div class="app-container">
        <SpielerDetailComponent/>
        <BottomBarDefault/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import SpielerDetailComponent from '@/components/Spieler/SpielerDetailComponent.vue'
import BottomBarDefault from '@/components/Utils/BottomBarDefault.vue'

export default {
    name: 'SpielerDetail',
    components: {
        SpielerDetailComponent,
        BottomBarDefault
    },
    computed: {
        loadingStatus() {
            return store.state.spieler.loadingStatus;
        },
    },
}
</script>
