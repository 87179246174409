<template>
  <div>
    <header>
      <router-link :to="{name: 'ListTurniere'}">
        <button class="btn-unstyled btn-back">
          <i class="fas fa-chevron-left"></i>
        </button>
      </router-link>
      <h2>{{lang.spielerDetail.headline}}</h2>
      <button class="btn-unstyled btn-open-search" @click="showDialogEditSpieler=true" v-if="isOnline">
        <i class="fas fa-pen"></i>
      </button>
    </header>
    <div class="container">
      <transition appear name="fadeRight" mode="out-in">
        <div class="spieler-content">
          <div class="img-container">
              <!-- <md-avatar class="md-avatar-icon md-primary">
                {{spieler.vorname ? spieler.vorname.charAt(0).toUpperCase() : ""}}
                {{spieler.vorname ? spieler.nachname.charAt(0).toUpperCase() : ""}}
              </md-avatar> -->
              <p class="p-unstyled name">{{spieler.vorname}} {{spieler.nachname}}</p>
              <p class="p-unstyled email">{{spieler.email}}</p>
          </div>
          <div class="spieler-data-container card" v-if="spieler != undefined">
            <md-list class="md-dense" md-expand-single=true>
              <md-list-item md-expand v-if="gruenderTurniereArray.length != 0">
                <md-icon>emoji_events</md-icon>
                <span class="md-list-item-text">{{lang.spielerDetail.erstellteTurniere}}</span>
                <md-list slot="md-expand">
                  <md-list-item v-for="(turnier, index) in gruenderTurniereArray" :key="index" class="md-inset">
                    <router-link class="turnier-link a-unstyled" :to="{ name: 'TurnierDetail', params: { turnierID: turnier.turnierID }}">
                      <span class="turnier-name">{{turnier.turnierName}}</span>
                      <span class="turnier-datum">{{dateFormatted(turnier.datum)}}</span>
                    </router-link>
                  </md-list-item>
                </md-list>
              </md-list-item>
              <md-list-item md-expand v-if="activeTurniereArray.length != 0">
                <md-icon>today</md-icon>
                <span class="md-list-item-text">{{lang.spielerDetail.aktiveTurniere}}</span>
                <md-list slot="md-expand">
                  <md-list-item v-for="(turnier, index) in activeTurniereArray" :key="index" class="md-inset">
                    <router-link class="turnier-link a-unstyled" :to="{ name: 'TurnierDetail', params: { turnierID: turnier.turnierID }}">
                      <span class="turnier-name">{{turnier.turnierName}}</span>
                      <span class="turnier-datum">{{dateFormatted(turnier.datum)}}</span>
                    </router-link>
                  </md-list-item>
                </md-list>
              </md-list-item>
              <md-list-item md-expand v-if="closedTurniereArray.length != 0">
                <md-icon>history</md-icon>
                <span class="md-list-item-text">{{lang.spielerDetail.vergangeneTurniere}}</span>
                <md-list slot="md-expand">
                  <md-list-item v-for="(turnier, index) in closedTurniereArray" :key="index" class="md-inset">
                    <router-link class="turnier-link a-unstyled" :to="{ name: 'TurnierDetail', params: { turnierID: turnier.turnierID }}">
                      <span class="turnier-name">{{turnier.turnierName}}</span>
                      <span class="turnier-datum">{{dateFormatted(turnier.datum)}}</span>
                    </router-link>
                  </md-list-item>
                </md-list>
              </md-list-item>
              <md-list-item md-expand>
                <md-icon>language</md-icon>
                <span class="md-list-item-text">{{lang.spielerDetail.changeLanguage}}</span>
                <md-list slot="md-expand">
                  <md-list-item :class="selectedLanguage=='de' ? 'active' : ''" @click="selectedLanguage='de'" class="md-inset">{{lang.spielerDetail.changeLanguageDE}}</md-list-item>
                  <md-list-item :class="selectedLanguage=='en' ? 'active' : ''" @click="selectedLanguage='en'" class="md-inset">{{lang.spielerDetail.changeLanguageEN}}</md-list-item>
                </md-list>
              </md-list-item>
              <md-list-item @click="showDialogEditSpieler=true" v-if="isOnline">
                <md-icon>create</md-icon>
                <span class="md-list-item-text">{{lang.spielerDetail.changeSpielerDaten}}</span>
              </md-list-item>
              <md-list-item @click="showDialogEditPasswort=true" v-if="isOnline">
                <md-icon>vpn_key</md-icon>
                <span class="md-list-item-text">{{lang.spielerDetail.changePasswort}}</span>
              </md-list-item>
              <md-list-item @click="logout">
                <md-icon>power_settings_new</md-icon>
                <span class="md-list-item-text">{{lang.spielerDetail.logout}}</span>
              </md-list-item>
            </md-list>
          </div>
          <nav class="nav-impressum">
            <ul class="ul-unstyled">
              <li><router-link :to="{ name: 'Impressum' }">{{lang.startseite.footerLinkText1}}</router-link></li>
              <li><router-link :to="{ name: 'Datenschutz' }">{{lang.startseite.footerLinkText2}}</router-link></li>
              <li><router-link :to="{ name: 'Kontakt' }">{{lang.startseite.footerLinkText3}}</router-link></li>
            </ul>
          </nav>
        </div>
      </transition>
    </div>
    <md-dialog :md-active.sync="showDialogEditSpieler" :md-fullscreen=false>
      <div class="dialog-content">
        <p class="headline p-unstyled">{{lang.spielerDetail.editSpielerHeadline}}</p>
        <form @submit.prevent="editSpieler" id="changeSpielerForm">
           <md-field class="md-field-icon" :class="getValidationClass('vorname')">
            <i class="fas fa-user-alt"></i>
            <label>{{lang.spielerForm.labelVorname}}</label>
            <md-input @blur="$v.spieler.vorname.$touch()" :name="lang.spielerForm.labelVorname" id="vorname" v-model="spieler.vorname" autocomplete="given-name" type="text"></md-input>
            <md-icon v-if="isFieldInvalid('vorname')" class="color-invalid">close</md-icon>
            <span class="md-error" v-if="!$v.spieler.vorname.required">{{lang.spielerForm.vornameRequired}}</span>
            <span class="md-error" v-else-if="!$v.spieler.vorname.minLength">{{$v.spieler.vorname.$params.minLength.min}} {{lang.spielerForm.vornameMinLength}}</span>
          </md-field>
          <md-field class="md-field-icon" :class="getValidationClass('nachname')">
            <i class="fas fa-user-alt"></i>
            <label>{{lang.spielerForm.labelNachname}}</label>
            <md-input @blur="$v.spieler.nachname.$touch()" :name="lang.spielerForm.labelNachname" id="nachname" v-model="spieler.nachname" autocomplete="family-name" type="text"></md-input>
            <md-icon v-if="isFieldInvalid('nachname')" class="color-invalid">close</md-icon>
            <span class="md-error" v-if="!$v.spieler.nachname.required">{{lang.spielerForm.nachnameRequired}}</span>
            <span class="md-error" v-else-if="!$v.spieler.nachname.minLength">{{$v.spieler.nachname.$params.minLength.min}} {{lang.spielerForm.nachnameMinLength}}</span>
          </md-field>
          <md-field class="md-field-icon" :class="getValidationClass('email')">
            <i class="fas fa-envelope"></i>
            <label>{{lang.spielerForm.labelEMail}}</label>
            <md-input @blur="$v.spieler.email.$touch()" :name="lang.spielerForm.labelEMail" id="email" v-model.trim="spieler.email" autocomplete="email" type="email"></md-input>
            <md-icon v-if="isFieldInvalid('email')" class="color-invalid">close</md-icon>
            <span class="md-error" v-if="!$v.spieler.email.required">{{lang.spielerForm.emailRequired}}</span>
            <span class="md-error" v-else-if="!$v.spieler.email.email">{{lang.spielerForm.emailRequired}}</span>
          </md-field>
          <md-dialog-actions>
            <md-button @click="cancelDialog()">{{lang.spielerForm.buttonTextCancel}}</md-button>
            <md-button type="submit" class="md-primary">{{lang.spielerForm.buttonTextAgree}}</md-button>
          </md-dialog-actions>
        </form>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="showDialogEditPasswort" :md-fullscreen=false>
      <div class="dialog-content">
        <p class="headline p-unstyled">{{lang.spielerDetail.editPasswortHeadline}}</p>
        <form @submit.prevent="editSpielerPasswort" id="changeSpielerForm">
          <md-field v-show="false">
            <label>{{lang.spielerForm.labelEMail}}</label>
            <md-input :name="lang.spielerForm.labelEMail" id="email" v-model.trim="spieler.email" autocomplete="email" type="email"></md-input>
          </md-field>
          <md-field class="md-field-icon" :class="$v.passwortData.passwortOld.$invalid && $v.passwortData.passwortOld.$dirty ? 'md-invalid' : ''">
            <i class="fas fa-unlock"></i>
            <label>{{lang.spielerForm.labelPasswortOld}}</label>
            <md-input @blur="$v.passwortData.passwortOld.$touch()" :name="lang.spielerForm.labelPasswortOld" id="passwortOld" v-model="passwortData.passwortOld" autocomplete="current-password" type="password"></md-input>
            <span class="md-error" v-if="!$v.passwortData.passwortOld.required">{{lang.spielerDetail.passwortOldRequired}}</span>
          </md-field>
          <md-field class="md-field-icon" :class="$v.passwortData.passwortNew.$invalid && $v.passwortData.passwortNew.$dirty ? 'md-invalid' : ''">
            <i class="fas fa-unlock"></i>
            <label>{{lang.spielerForm.labelPasswortNew}}</label>
            <md-input @blur="$v.passwortData.passwortNew.$touch()" :name="lang.spielerForm.labelPasswortNew" id="passwortNew" v-model="passwortData.passwortNew" autocomplete="new-password" type="password"></md-input>
            <span class="md-error" v-if="!$v.passwortData.passwortNew.required">{{lang.spielerDetail.passwortNewRequired}}</span>
            <span class="md-error" v-else-if="!$v.passwortData.passwortNew.minLength">{{$v.passwortData.passwortNew.$params.minLength.min}} {{lang.spielerForm.passwortMinLength}}</span>
          </md-field>
          <md-field class="md-field-icon" :class="$v.passwortData.passwortConfirm.$invalid && $v.passwortData.passwortConfirm.$dirty ? 'md-invalid' : ''">
            <i class="fas fa-lock"></i>
            <label>{{lang.spielerForm.labelPasswortRepeat}}</label>
            <md-input @blur="$v.passwortData.passwortConfirm.$touch()" :name="lang.spielerForm.labelPasswortRepeat" id="passwortConfirm" v-model="passwortData.passwortConfirm" autocomplete="new-password" type="password"></md-input>
            <span class="md-error" v-if="!$v.passwortData.passwortConfirm.sameAsPassword">{{lang.spielerForm.passwortConfirm}}</span>
          </md-field>
          <md-dialog-actions>
            <md-button @click="showDialogEditPasswort=false">{{lang.spielerForm.buttonTextCancel}}</md-button>
            <md-button type="submit" class="md-primary">{{lang.spielerForm.buttonTextAgree}}</md-button>
          </md-dialog-actions>
        </form>
      </div>
    </md-dialog>
    <SubmitModal/>
  </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import SubmitModal from '../Utils/SubmitModal'
import {required, minLength, email, sameAs} from 'vuelidate/lib/validators'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'SpielerDetailComponent',
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal
  },
  data() {
    return {
      showDialogEditSpieler: false,
      showDialogEditPasswort: false,
      selectedLanguage: '',
      passwortData: {
        email: '',
        passwortOld: '',
        passwortNew: '',
        passwortConfirm: ''
      }
    }
  },
  validations: {
    spieler: {
      nachname: {
        required,
        minLength: minLength(2)
      },
      vorname: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
    },
    passwortData: {
      passwortOld: {
        required
      },
      passwortNew: {
        required,
        minLength: minLength(5)
      },
      passwortConfirm: {
        sameAsPassword: sameAs('passwortNew')
      }
    }
  },
  created() {
    store.dispatch('getSpielerData');
    store.dispatch('getActiveTurniere');
    store.dispatch('getClosedTurniere');
    store.dispatch('getGegruendeteTurniere', true);
    store.dispatch('getGegruendeteTurniere', false);
    this.selectedLanguage = this.getLocalStorageVariable("language");
  },
  watch: {
    selectedLanguage(lang) {
      store.commit("SET_LANGUAGE", lang);
    }
  },
  methods: {
    logout() {
      store.dispatch('logout');
      router.push({name: 'Login'});
    },
    getLocalStorageVariable(localStorageVariableName) {
      return localStorage.getItem(localStorageVariableName) ? localStorage.getItem(localStorageVariableName) : '';
    },
    getValidationClass(fieldName) {
      return {
        'md-invalid': this.isFieldInvalid(fieldName)
      }
    },
    isFieldInvalid(fieldName) {
      const field = this.$v.spieler[fieldName]
      if (field) {
        return field.$invalid && field.$dirty;
      }
    },
    async editSpieler() {
      this.$v.spieler.$touch();
      if (!this.$v.spieler.$invalid) {
        await store.dispatch('putSpieler', this.spieler)
          .then(result => {
            if (result.data != null) {
              this.showDialogEditSpieler = false;
              this.showDialogActive(true, this.lang.spielerDetail.successHeadline, "");
              setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
            } else {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage);
            }
          })
          .catch(error => {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage)
          });
      }
    },
    async editSpielerPasswort() {
      this.$v.passwortData.$touch();
      let requestData = {
        email: this.spieler.email,
        passwortOld: this.passwortData.passwortOld,
        passwortNew: this.passwortData.passwortNew
      }
      if (!this.$v.passwortData.$invalid) {
        await store.dispatch('putSpielerPasswort', requestData)
          .then(result => {
            if(result.data != null) {
              this.showDialogEditPasswort = false;
              this.showDialogActive(true, this.lang.spielerDetail.successHeadline, "");
              setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
            } else {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage);
            }
          })
          .catch(error => {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage)
          });
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
        store.commit("SET_SHOW_SUBMIT_MODAL", {
          show: true,
          success: formSuccess,
          headline: formSuccessHeadline,
          message: formSuccessMessage
      });
    },
    dateFormatted(dateString) {
      if (dateString != undefined || dateString != null) {
        let date = dateString.split("-");
        return date[2] + "." + date[1] + "." + date[0];
      } else {
        return "";
      }
    },
    cancelDialog() {
      this.showDialogEditSpieler=false;
      store.dispatch('getSpielerData');
    },
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    spieler() {
      return store.getters.getSpielerData;
    },
    gruenderTurniereArray() {
      return store.getters.getGruenderTurniereArray;
    },
    activeTurniereArray() {
      return store.getters.getActiveConcatTurniere;
    },
    closedTurniereArray() {
      return store.getters.getClosedConcatTurniere;
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';

.img-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 0 25px 0;

  .md-avatar {
    border-radius: 50%;
    height: 100px;
    margin-bottom: 15px;
    width: 100px;
  }

  .name {
    font-weight: 500;
    font-size: 1.8rem;
  }

  .email {
    font-size: 1.4rem;
  }
}

.spieler-data-container {
  padding: 5px 15px 5px 15px;
}

.dialog-content {
    padding: 30px 20px 20px 20px;

    .headline {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .md-button:first-of-type {
        margin-left: 0;
    }

    .md-button:last-of-type {
        margin-right: 0;
    }
}

.md-inset {
  margin-bottom: 10px;
}

.md-list-item {
  margin-bottom: 5px;

  .turnier-link {
    display: flex;
    flex-direction: column;
  }

  .turnier-name {
    color: $brand-secondary;
    margin-bottom: 5px;
  }

  .turnier-datum {
    font-size: 1.1rem;
    color: #7c7c7c;
  }

  button {
    font-size: 1.4rem;
  }

  .active button {
    color: $brand-secondary !important;
  }
}

.nav-impressum {
  margin-bottom: 10px;

  li {
    margin-bottom: 5px;
    text-align: center;
  }

  a {
    color: $color-fliestext;
    font-size: 1.3rem;
  }
}

@media (min-width: $screen-xs) {
  $font-size-listitem: 1.5rem;

  .img-container {
    .name {
      font-size: 2rem;
    }

    .email {
      font-size: 1.6rem;
    }
  }
  .md-list-item {
    .md-list-item-text {
      font-size: $font-size-listitem;
    }
    .md-list-item-content {
      font-size: 1.4rem;
    }
    .turnier-name {
      font-size: 1.4rem;
    }
    
    .turnier-datum {
      font-size: 1.2rem;
    }
  }

  .nav-impressum {
    li {
      margin-bottom: 5px;
    }

    a {
      font-size: $font-size-listitem;
    }
  }
}


</style>