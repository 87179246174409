<template>
  <nav>
    <md-bottom-bar md-sync-route>
      <md-bottom-bar-item exact :to="{name:'Home'}" md-active-item="home" :md-label="lang.bottomBar.home" md-icon="home"></md-bottom-bar-item>
      <md-bottom-bar-item :to="{name: 'ListTurniere'}" :md-label="lang.bottomBar.turniere" md-icon="emoji_events"></md-bottom-bar-item>
      <md-bottom-bar-item :to="spieleRoute()" :md-label="lang.bottomBar.spielPlan" md-icon="sports_bar"></md-bottom-bar-item>
      <md-bottom-bar-item :to="{name: 'SpielerDetail'}" :md-label="lang.bottomBar.spielerDetail" md-icon="person"></md-bottom-bar-item>
    </md-bottom-bar>
  </nav>
</template>

<script>
import store from '@/store/index'

export default {
  name: 'BottomBarDefault',
  data() {
    return {
      SPIELMODUS: store.getters.getSpielModus,
    }
  },
  created() {
    store.dispatch('getActiveTurniere');
    store.dispatch('getGegruendeteTurniere', false);
  },
  methods: {
    spieleRoute() {
      if (this.activeTurniereOfSpieler.length == 1) {
        return {name: this.activeTurniereOfSpieler[0].spielmodus == this.SPIELMODUS.GRUPPEN_SYSTEM.name ? 'ListGruppenSpiele' : 'ListSingleSpiele', params: {turnierID: this.activeTurniereOfSpieler[0].turnierID}};
      } else {
        return {name: 'SpieleUebersicht'}
      }
    }
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    activeTurniereOfSpieler() {
      return store.getters.getActiveConcatTurniere;
    }
  },
}
</script>

<style lang="scss">
.md-bottom-bar.md-type-fixed {
  .md-bottom-bar-item {
    max-width: none;

    &.md-active {
      .md-bottom-bar-label {
        transform: scale(.8571) translate3D(0,4px,0) !important;
      }

      .md-bottom-bar-icon {
        transform: translate3d(0,-2px,0) !important;
      }

      .md-ripple {
        padding-top: 8px !important;
      }
    }
  }
}

</style>


<style lang="scss" scoped>
.md-bottom-bar {
  position: fixed;
  box-shadow: 1px 3px 9px 0px rgba(127,127,127,0.96);
  width: 100%;
  bottom: 0;
  z-index: 6;
}
</style>